import {
  Component, HostListener, OnInit
} from '@angular/core';
import { NavigationStart } from '@angular/router';
import { Factory } from './app.factory';

import {
  Router
} from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalAuthRefreshNavBarService } from './common/eso/local-auth.refresh-navbar';
// import 'rxjs/add/operator/retryWhen';
// import { retryWhen } from 'rxjs/operators';

import { ReturnMessage } from './common/enum/type.enum';
import { UserProfileService } from './common/eso/userprofile.service';
import { TransRoleToView } from './common/services/RoleToView/TransRoleToView';
import { AuthForUserGroupService } from './common/services/UserAuth/AuthForUserGroupService';
import { AuthService } from './common/services/UserAuth/AuthService';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { resolve } from 'path';

import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Factory],
})
export class AppComponent implements OnInit {
  subscription: Subscription;
  private service: any;
  private userInfor: any;
  private userGroupInfor: any;
  private routerSub$: Subscription;
  public roleConfig: any;
  loggedIn = false;
  token: any;
  constructor(
    private factory: Factory,
    private router: Router,
    private auth: AuthService,
    private authForUserGroup: AuthForUserGroupService,
    private UserProfile: UserProfileService,
    private rebarAuthService: RebarAuthService,
    private http: HttpClient
  ) {
    // eslint-disable-next-line no-debugger
    // debugger
    this.service = this.factory.getService();
    this.routerSub$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.toLowerCase().lastIndexOf('/noaccess') > -1) {
          return;
        }

        // eslint-disable-next-line no-debugger
        // debugger
        // console.log(`003 app.component event.url：${event.url.toLowerCase()}`);
        var routeStr = event.url.toLowerCase().split('?')[0];
        // console.log(`004 app.component routeStr：${routeStr}`);
        routeStr = routeStr.split('#')[0];
        // console.log(`005 app.component routeStr：${routeStr}`);
        // if (routeStr === '/home') {
        //   return;
        // }
        var arr = event.url.split('/');
        var pagename1 = '';
        if (arr.length > 1) {
          var arr1 = arr[1].toString().split('?');
          pagename1 = arr1[0];
        } else {
          pagename1 = '';
        }
        var pagename2 = '';
        if (arr.length > 2) {
          var arr2 = arr[2].toString().split('?');
          pagename2 = arr2[0];
        } else {
          pagename2 = '';
        }

        // this.token = this.rebarAuthService.getAccessToken().then(response => {
        //   console.log('006 this.token response:', response);
        // });
        // console.log('007 this.token:', this.token);
        // eslint-disable-next-line no-debugger
        // debugger
        var eid = this.UserProfile.EnterpriseId;
        // console.log(`008 app.component eid: ${eid}`);
        if (eid === '') {
          return;
        }
        var data = { Eid: eid };
        var userInfo = this.auth.getUserInforFn();
        if (userInfo.EID && userInfo.EID === eid) {
          this.userInfor = userInfo;
          this.checkUserAccess(this.userInfor, pagename1, pagename2);
        } else {
          // eslint-disable-next-line no-debugger
          // debugger
          this.service.getUserByEid(data).then(
            // this.http.get<any>('user/getUserByEid').subscribe(
            (res) => {
              console.log('res:', res)
              // eslint-disable-next-line no-debugger
              // debugger
              if (res.Status == ReturnMessage.Success) {
                var user = {
                  EID: res.Data.EID,
                  LogInAs: res.Data.LogInAs,
                  Uri: res.Data.Uri,
                  Email: res.Data.Email,
                  Group: res.Data.Group,
                  KeyCategory: res.Data.KeyCategory,
                  Gu: res.Data.Gu,
                  OPEmail: res.Data.OPEmail,
                  Salutation: res.Data.Salutation,
                  UserCountry: res.Data.UserCountry,
                  GuLead: res.Data.GuLead,
                  EmailContact: res.Data.EmailContact,
                };
                this.auth.setUserInforFn(user);
                this.userInfor = user;
                this.checkUserAccess(this.userInfor, pagename1, pagename2);
                sessionStorage.setItem("emailContact", user.EmailContact);
              }
            },
            (err) => {
              console.log('get user error', err);
            }
          );
        }
      }
    });
    LocalAuthRefreshNavBarService.getLoggedInName.subscribe((name) =>
      this.ngOnInit()
    );
  }

  checkoutAccount() {
    this.loggedIn = this.rebarAuthService.isUserAuthenticated();
  }

  logout() {
    this.rebarAuthService.logout();
  }

  // login() {
  //   this.rebarAuthService.login();
  // }

  ngOnInit() {
    // const esoToken = sessionStorage.getItem('adal.access.token.key');
    this.rebarAuthService.authObserver$.subscribe((authStatus) => {
      console.log(`Rebar&Masl2.0 ---------- authStatus: ${authStatus}`);
      
      if (authStatus) {
        console.log(`Rebar&Masl2.0 Username ****** ${this.rebarAuthService.getUser()}`);
      }
    });
  }

  ngOnDestroy() {
    window.localStorage.removeItem("NotificationComponentLoadAgain");
    this.routerSub$.unsubscribe();
  }

  @HostListener('document:mouseover', ['$event'])
  onMouseOver(event) {
    var nodeObj = event.srcElement ? event.srcElement : event.target;
    do {
      // auto add title for "mat-cell" without .not-auto-ellipsis and .not-auto-title
      if (['MAT-CELL'].indexOf(nodeObj.nodeName) !== -1) {
        if (nodeObj.className.split(' ').indexOf('not-auto-ellipsis') == -1
          && nodeObj.className.split(' ').indexOf('not-auto-title') == -1
          && !nodeObj.title) {
            var childNodes = nodeObj.childNodes;

          if (childNodes.length === 1 && childNodes[0].nodeName === '#text') {
            nodeObj.title = childNodes[0].textContent;
          }
        }
        break;
      }
      var $node = $(nodeObj);
      if ($node.is('button') && $node.closest('mat-table').length != 0) {
        nodeObj.title = $node.text();
      }
      nodeObj = nodeObj.parent;
    } while (nodeObj);
  }

  checkUserAccess(user: any, pagename1: any, pagename2: any) {
    console.log('app-checkUserAccess:');
    console.log('user:', user);
    console.log('pagename1:', pagename1);
    console.log('pagename2:', pagename2);
    if (user.EID != '') {
      if (user.Group.length > 0) {
        var group = this.auth.determineUserRole();
        var RoleInstance = new TransRoleToView(group); // find user can use page
        this.roleConfig = RoleInstance.getRoleToComponent();
        // compare url and user's role page
        var compareresult = this.compareUrl(
          pagename1,
          pagename2,
          this.roleConfig
        );
        if (!compareresult) {
          // var data = { Eid: user.EID };
          // this.service.getUserGroupOPEmailByEid(data).then(res => {  //get user's opEmail
          //   if (res.Status == ReturnMessage.Success) {
          //     var userGroup = {
          //       Eid: res.Data.Eid,
          //       OPEmail: res.Data.OPEmail,
          //       Group: res.Data.Group
          //     };
          //console.log('from app.components 1 with no access');
          this.router.navigate(['/noaccess'], {
            queryParams: { email: user.OPEmail },
          });
          // }
          // });
        }
      } else {
        // var data = { Eid: user.EID };
        // this.service.getUserGroupOPEmailByEid(data).then(res => {
        //   if (res.Status == ReturnMessage.Success) {
        //     var userGroup = {
        //       Eid: res.Data.Eid,
        //       OPEmail: res.Data.OPEmail,
        //       Group: res.Data.Group
        //     };
        //console.log('from app.components 2 with no access');
        this.router.navigate(['/noaccess'], {
          queryParams: { email: user.OPEmail },
        });
        //   }
        // });
      }
    }
  }

  compareUrl(pagename1: any, pagename2: any, roleConfig: any) {
    if (pagename1 == '') {
      return true;
    } else if (pagename1 == 'login' || pagename1 == 'login#') {
      // login url only adapt local environment
      return true;
    } else if (pagename1 == 'administrator') {
      return roleConfig.ComponentConfig.menuheader.administrator;
    } else if (pagename1 == 'rs') {
      if (!roleConfig.ComponentConfig.mypages) {
        if (pagename2 == 'RequiredActions') {
          return roleConfig.ComponentConfig.menuheader.mypages
            .myrequiredactions;
        } else if (pagename2 == 'saveInProgress') {
          return roleConfig.ComponentConfig.menuheader.mypages
            .myinprogresssubmissions;
        } else if (pagename2 == 'SubmissionPending') {
          return roleConfig.ComponentConfig.menuheader.mypages
            .mypendingsubmissions;
        } else if (pagename2 == 'MyRecords') {
          return roleConfig.ComponentConfig.menuheader.mypages.myrecords;
        } else if (pagename2 == 'ExpiringContracts') {
          return roleConfig.ComponentConfig.menuheader.mypages
            .ExpiringContracts;
        }
      }
    } else if (pagename1 == 'newrecord') {
      return roleConfig.ComponentConfig.menuheader.newrecord;
    } else if (pagename1 == 'home') {
      return roleConfig.ComponentConfig.menuheader.home;
    } else if (pagename1 == 'dashboard') {
      return roleConfig.ComponentConfig.menuheader.dashboard;
    } else if (pagename1 == 'mainsearch') {
      return roleConfig.ComponentConfig.menuheader.mainsearch;
    } else if (pagename1 == 'searchrecord') {
      return roleConfig.ComponentConfig.menuheader.searchrecord;
    } else if (pagename1 == 'vactr') {
      if (pagename2 == 'standardViewActiveRecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord
          .standardViewActiveRecord;
      } else if (pagename2 == 'requesterViewPendingRecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord
          .requesterViewPendingRecord;
      } else if (pagename2 == 'adminViewActiveRecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord
          .adminViewActiveRecord;
      } else if (pagename2 == 'docOwnerViewActiveRecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord
          .docOwnerViewActiveRecord;
      }
    } else if (pagename1 == 'vr') {
      if (pagename2 == 'history') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.history;
      } else if (pagename2 == 'recordFamily') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.recordFamily;
      } else if (pagename2 == 'myRaViewRecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.myRaViewRecord;
      } else if (pagename2 == 'sipviewrecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.sipviewrecord;
      } else if (pagename2 == 'spviewrecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.spviewrecord;
      } else if (pagename2 == 'adminViewRecord') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.adminViewRecord;
      } else if (pagename2 == 'addSupportingDocument') {
        return roleConfig.ComponentConfig.menuheader.viewrecord
          .addSupportingDocument;
      } else if (pagename2 == 'terminationView') {
        return roleConfig.ComponentConfig.menuheader.viewrecord.terminationView;
      }
    } else {
      return false;
    }
  }
}
